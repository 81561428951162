import React, { Component } from "react"
import { Link } from "gatsby"
import "./header.scss"
import {
  Container,
  Row,
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
} from "reactstrap"

class Header extends Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
    }
  }

  toggle = (newState) => {
    if(newState === undefined || newState === null){
      newState = !this.state.isOpen
    } else if(newState === this.state.isOpen){
       return;
    }
    this.setState({
      isOpen: newState,
    })
  }

  render() {
    const { siteTitle, logo } = this.props
    return (
      <div className="header">
        {/* color="dark" dark properties for Navbar */}
        <Navbar expand="md" className="navbar fixed-top">
          <Link to="/#hero" className="navbar-brand">
            <img
              src={logo}
              alt={`${siteTitle} Logo`}
              height="40px"
              className="me-2"
            />
            {/* {siteTitle} */}
          </Link>
            <NavbarToggler onClick={()=>this.toggle()} 
              style={{ border: 'none',
                      WebkitTapHighlightColor: 'transparent',
                      outline: 0 }}/>
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ms-auto" navbar>
                <NavItem>
                  <Link
                    className="nav-link"
                    activeStyle={{ fontWeight: "bold" }}
                    to="/"
                    onClick={()=>this.toggle(false)}
                  >
                    Home
                  </Link>
                </NavItem>
                <NavItem>
                  <Link 
                    className="nav-link"
                    activeStyle={{ fontWeight: "bold" }}
                    to="/gallery"
                    onClick={()=>this.toggle(false)}
                  >
                    Projects
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    className="nav-link"
                    activeStyle={{ fontWeight: "bold" }}
                    to="/services"
                    onClick={()=>this.toggle(false)}
                  >
                    Services
                  </Link>
                </NavItem>
                {/* <NavItem>
                  <Link
                    className="nav-link"
                    activeStyle={{ fontWeight: "bold" }}
                    to="/careers/#careers"
                    onClick={()=>this.toggle(false)}
                  >
                    Careers
                  </Link>
                </NavItem> */}
                <NavItem>
                  <Link
                    className="nav-link"
                    activeStyle={{ fontWeight: "bold" }}
                    to="/about"
                    onClick={()=>this.toggle(false)}
                  >
                    About Us
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    className="nav-link"
                    activeStyle={{ fontWeight: "bold" }}
                    to="/reviews"
                    onClick={()=>this.toggle(false)}
                  >
                    Reviews
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    className="nav-link"
                    activeStyle={{ fontWeight: "bold" }}
                    to="/contact"
                    onClick={()=>this.toggle(false)}
                  >
                    Contact
                  </Link>
                </NavItem>
              </Nav>
            </Collapse>
        </Navbar>
      </div>
    )
  }
}

export default Header
